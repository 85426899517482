<template>
  <div class="loading-popup flex align-center justify-center">
    <van-loading type="spinner" color="#1989fa" vertical>正在加载数据...</van-loading>
  </div>
</template>

<script>
export default {
name: "loading",
  data () {
    return {
      show: true
    }
  }
}
</script>

<style scoped lang="scss">
</style>
<style>
.loading-popup{
  position: fixed;z-index: 147;
  width: 100%; height: 100%;
  background: rgba(0, 0, 0, .7);
}
</style>
