// import axios from 'axios';
import {Toast} from 'vant';
/**
 *  所有共用页面中的接口
 * **/

export default {
  /**
   * 运营商
   * **/
  getOperatorsLabel (val) {
    let list = [{
      value: 'CMCC',
      label: '移动'
    },
      {
        value: 'CTCC',
        label: '电信'
      }, {
        value: 'CUCC',
        label: '联通'
      }];
    let obj = list.find((e) => {
      return e.value == val
    })
    return obj ? obj.label : '';
  },
  /**
   * 获取当前年往前推5年 (入学年分选择)
   * num (往前推的数量)
   * **/
  getEnrolledyear (type) {
    let fullYear = new Date().getFullYear();
    let list = [];
    for (var i = fullYear - type; i <= fullYear; i++) {
      list.push(i);
    }
    return list;
  },
  /**
   * 是否是微信端
   * **/
  isWeiXin(){
    var ua = window.navigator.userAgent.toLowerCase();
    if(ua.match(/MicroMessenger/i) == 'micromessenger' && ua.match(/wxwork/i) != 'wxwork'){
      return true;
    } else {
      return false;
    }
  },
  /* 判断是否是企业微信 */
  isWxwork() {
    var ua = window.navigator.userAgent.toLowerCase();
    if(ua.match(/MicroMessenger/i) == 'micromessenger'  && ua.match(/wxwork/i) == 'wxwork'){
      return true;
    } else {
      return false;
    }
  },
  /**
   * 复制功能
   * **/
  copy (val, msg) {
    let oInput = document.createElement('input');
    oInput.value = val;
    document.body.appendChild(oInput);
    oInput.select(); // 选择对象
    document.execCommand('Copy'); // 执行浏览器复制命令
    oInput.className = 'oInput';
    oInput.style.display = 'none';
    Toast.success(msg);
  }
}
