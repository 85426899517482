const common = {
  // 计算除去标题和底部tab栏等的高度，赋予loadmore组件固定高度
  /* params
  * el - 要赋予高度的dom
  * ...vaule - 所有要减去dom的高度
  * eg: this.$common.setLoadmoreHeight(document.getElementsByClassName('demo-loadmore-wrap')[0], document.getElementsByClassName('mu-bottom-nav')[0].clientHeight);
  */
  setLoadmoreHeight (el, ...values) {
    let winH = document.body.clientHeight;
    let result = winH;
    for (let i = 0, len = values.length; i < len; i++) {
      result = result - values[i];
    }
    el.style.height = result + 'px';
  },
  // 转换时间日期今天、昨天、xx-xx
  /*
  * 时间戳/日期时间格式字符串 => hh:mm、昨天 hh:mm、MM-dd hh:mm
  */
  getdatestr (btime) {
    btime = new Date(btime);
    var _byd = getdate(-2); // 前天0点
    var _yd = getdate(-1); // 昨天0点
    var _td = getdate(0); // 今天0点
    var _tomd = getdate(1); // 明天0点
    var strHM = btime.getHours() + ':' + btime.getMinutes();
    if (btime < _byd) {
      // 前天之前，返回月，日
      return btime.getMonth() + 1 + '-' + btime.getDate();
    } else if (btime >= _byd && btime < _yd) {
      // 前天
      return '前天 ' + strHM;
    } else if (btime >= _yd && btime < _td) {
      // 昨天
      return '昨天 ' + strHM;
    } else if (btime >= _td && btime < _tomd) {
      // 今天
      return strHM;
    } else {
      return btime.getMonth() + 1 + '-' + btime.getDate() + ' ' + strHM;
    }
  },
  // 转换时间  （xx:xx， 几天前， 几分钟前）
  /*
  * 时间戳/日期时间格式字符串 => xx:xx， 几天前， 几分钟前
  */
  getDateDiff (dateStr) {
    var publishTime = this.getDateTimeStamp(dateStr) / 1000;
    var dSeconds;
    var dMinutes;
    var dHours;
    var dDays;
    var timeNow = parseInt(new Date().getTime() / 1000);
    var d;

    var date = new Date(publishTime * 1000);
    var Y = date.getFullYear();
    var M = date.getMonth() + 1;
    var D = date.getDate();
    var H = date.getHours();
    var m = date.getMinutes();
    var s = date.getSeconds();
    // 小于10的在前面补0
    if (M < 10) {
      M = '0' + M;
    }
    if (D < 10) {
      D = '0' + D;
    }
    if (H < 10) {
      H = '0' + H;
    }
    if (m < 10) {
      m = '0' + m;
    }
    if (s < 10) {
      s = '0' + s;
    }

    d = timeNow - publishTime;
    dDays = parseInt(d / 86400);
    dHours = parseInt(d / 3600);
    dMinutes = parseInt(d / 60);
    dSeconds = parseInt(d);

    if (dDays > 0 && dDays < 3) {
      return dDays + '天前';
    } else if (dDays <= 0 && dHours > 0) {
      return dHours + '小时前';
    } else if (dHours <= 0 && dMinutes > 0) {
      return dMinutes + '分钟前';
    } else if (dSeconds < 60) {
      if (dSeconds <= 0) {
        return '刚刚';
      } else {
        return dSeconds + '秒前';
      }
    } else if (dDays >= 3 && dDays < 30) {
      return M + '-' + D + ' ' + H + ':' + m;
    } else if (dDays >= 30) {
      return Y + '-' + M + '-' + D + ' ' + H + ':' + m;
    }
  },
  getDateTimeStamp (dateStr) {
    return Date.parse(dateStr.replace(/-/gi, '/'));
  },
  // 转换本地图片文件为base64格式
  /* 使用callback来取得base64的内容
  * cb的参数即转换后的base64内容
  * 使用参考：
    this.$common.picToBase64(file, (res) => {
      let result = res;
    });
  */
  picToBase64 (file, cb) {
    const reader = new FileReader();
    // 将文件以Data URL形式读入页面
    reader.readAsDataURL(file);
    reader.onload = function () {
      // var ImgFileSize = reader.result.substring(reader.result.indexOf(",") + 1).length;//截取base64码部分（可选可不选，需要与后台沟通）
      cb(reader.result);
    };
  },
  // 获取当前地址栏的参数
  getQueryString (name) {
    var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)');
    var r = window.location.search.substr(1).match(reg);
    if (r != null) return unescape(r[2]); return null;
  },
  // 分钟转换  时/分
  ChangeHourMinutestr (str) {
    if (str !== '0' && str !== '' && str !== null) {
      return ((Math.floor(str / 60)).toString().length < 2 ? '0' + (Math.floor(str / 60)).toString() : (Math.floor(str / 60)).toString()) + ':' + ((str % 60).toString().length < 2 ? '0' + (str % 60).toString() : (str % 60).toString());
    } else {
      return '';
    }
  },
  // 分钟转换
  ChangeStrToMinutes (str) {
    var arrminutes = str.split(':');
    if (arrminutes.length === 2) {
      var minutes = parseInt(arrminutes[0]) * 60 + parseInt(arrminutes[1]);
      return minutes;
    } else {
      return 0;
    }
  },

  // 专门处理后台返回的（分钟 ## 分钟 ， 分钟 ## 分钟） （处理成数组）
  returnMinutes (str) {
    let a = [];
    let b = [];
    if (str) {
      a = str.split(',');
      for (var i = 0; i < a.length; i++) {
        a[i] = a[i].split('##');
        b.push(a[i][0]);
        b.push(a[i][1]);
      }
    } else {
      return '';
    }
    return b;
  },

  ifCloseLoading: false,
  // 关闭初始化时loading动画
  closeLoadingInit () {
    let dom = document.getElementById('loadContainer');
    let child = document.getElementsByClassName('loadingBox')[0];
    if (!this.ifCloseLoading && child != null) {
      this.ifCloseLoading = true;
      dom.removeChild(child);
      dom.style.display = 'none';
    }
  },
  // 关闭初始化时loading动画
  openLoadingInit () {
    let dom = document.getElementById('loadContainer');
    let child = document.getElementsByClassName('loadingBox')[0];
    if (!this.ifCloseLoading && child != null) {
      this.ifCloseLoading = false;
      dom.removeChild(child);
      dom.style.display = 'block';
    }
  },
  // 获取时间  转换格式 (YYYY-MM-DD)
  getDateYMD (time, interval) {
    let date = time ? new Date(time) : new Date();
    let year = date.getFullYear();
    let month = date.getMonth() + 1;
    let day = date.getDate();
    let times = year + interval + (+month < 10 ? ('0' + month) : month) + interval + (+day < 10 ? ('0' + day) : day);

    return times;
  },
  // 获取地址栏的参数
  getQueryVariable (variable) {
    var query = window.location.search.substring(1);
    var vars = query.split('&');
    for (let i = 0; i < vars.length; i++) {
      var pair = vars[i].split('=');
      if (pair[0] === variable) { return pair[1]; }
    }
    return (false);
  },
  // 根据开始时间和结束时间  计算中间所有的日期
  getAllDate (start, end) {
    const format = (time) => {
      let ymd = '';
      let mouth = (time.getMonth() + 1) >= 10 ? (time.getMonth() + 1) : ('0' + (time.getMonth() + 1));
      let day = time.getDate() >= 10 ? time.getDate() : ('0' + time.getDate());
      ymd += time.getFullYear() + '-'; // 获取年份。
      ymd += mouth + '-'; // 获取月份。
      ymd += day; // 获取日。
      return ymd; // 返回日期。
    };
    let dateArr = [];
    let startArr = start.split('-');
    let endArr = end.split('-');
    let db = new Date();
    db.setUTCFullYear(startArr[0], startArr[1] - 1, startArr[2]);
    let de = new Date();
    de.setUTCFullYear(endArr[0], endArr[1] - 1, endArr[2]);
    let unixDb = db.getTime();
    let unixDe = de.getTime();
    let stamp;
    const oneDay = 24 * 60 * 60 * 1000;
    for (stamp = unixDb; stamp <= unixDe;) {
      dateArr.push(format(new Date(parseInt(stamp))));
      stamp = stamp + oneDay;
    }
    return dateArr;
  },
  // 时间戳转换剩余时间
  /*
  * params: times - 截止时间的毫秒数
  *         obj - 要赋值的变量对象，至少包含 hour、minute、second属性，包含day则计算天数
  */
  getLastTime: function (times, obj, cb) {
    let timer;
    let nowTime = new Date().getTime();
    let lasttime = times - nowTime;
    timer = setInterval(() => {
      if ((lasttime - 1000) <= 0) {
        if ('day' in obj) {
          obj.day = '00';
        }
        obj.hour = '00';
        obj.minute = '00';
        obj.second = '00';
        clearInterval(timer);
        cb && cb();
      } else {
        let dayStr = Math.floor(lasttime / 24 / 60 / 60 / 1000);
        let hourStr = Math.floor((lasttime / 60 / 60 / 1000) % 24);
        let minuteStr = Math.floor((lasttime / 60 / 1000) % 60);
        let secondStr = Math.floor((lasttime / 1000) % 60);
        if ('day' in obj) {
          obj.day = dayStr > 9 ? dayStr : ('0' + dayStr);
          obj.hour = hourStr > 9 ? hourStr : ('0' + hourStr);
        } else {
          obj.hour = (dayStr * 24 + hourStr) > 9 ? (dayStr * 24 + hourStr) : ('0' + hourStr);
        }
        obj.minute = minuteStr > 9 ? minuteStr : ('0' + minuteStr);
        obj.second = secondStr > 9 ? secondStr : ('0' + secondStr);
        nowTime = new Date().getTime();
        lasttime = times - nowTime;
      }
    }, 1000);
  }
};

function getdate (AddDayCount) {
  var dd = new Date();
  dd.setDate(dd.getDate() + AddDayCount); // 获取AddDayCount天后的日期
  var y = dd.getFullYear();
  var m = dd.getMonth() + 1; // 获取当前月份的日期
  var d = dd.getDate();
  return new Date(y + '-' + m + '-' + d);
}
export default common;
