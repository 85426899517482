import Vue from 'vue';
import Router from 'vue-router';
const broadband = () => import('@/views/business/kdform'); // 宽带表单
const opencard = () => import('@/views/business/kkform'); // 开卡表单
const marketing = () => import('@/views/business/yxform'); // 营销表单

const kkresult = () => import('@/views/business/kk-result-pay.vue');  // 开卡支付结果
const yxresult = () => import('@/views/business/yx-result-pay.vue'); // 营销支付结果
const kdresult = () => import('@/views/business/result-pay.vue'); // 宽带支付结果
const order = () => import('@/views/business/order.vue'); // 我的订单
const details = () => import('@/views/business/details.vue'); // 我的订单详情
const officialAccount = () => import('@/views/officialAccount.vue'); // 关注大学掌公众号
const marketingList = () => import('@/views/marketingList.vue'); // 营销活动（大学掌）

Vue.use(Router);

export default new Router({
  mode: 'history',
  // base: '/cs/', // 正式需要注释
  routes: [
    {
      path: '*'
    },
    {
      path: '/broadband',
      name: 'broadband',
      component: broadband
    },
	{
		path: '/opencard', //开卡表单
		name: 'opencard',
		component: opencard
	},
	{
		path: '/marketing', //营销表单
		name: 'marketing',
		component: marketing
	},
    {
      path: '/kdresult',
      name: 'kdresult',
      component: kdresult

	},
    {
      path: '/yxresult',
      name: 'yxresult',
      component: yxresult

	},
    {
      path: '/kkresult',
      name: 'kkresult',
      component: kkresult

	},
    {
      path: '/order',
      name: 'order',
      component: order
    },
    {
      path: '/details',
      name: 'details',
      component: details,
    },
    {
      path: '/officialAccount',
      name: 'officialAccount',
      component: officialAccount
    },
    {
      path: '/marketingList',
      name: 'marketingList',
      component: marketingList
    }
  ]
});
//获取原型对象上的push函数
const originalPush = Router.prototype.push
//修改原型对象中的push方法
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
