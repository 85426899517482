import Vue from 'vue';
import axios from 'axios';
// import Qs from 'qs';
import VueAxios from 'vue-axios';
import store from '../store/store';
// import Router from '../router/index';
import { baseUrl } from '@/http/baseUrl';

Vue.use(VueAxios, axios);
axios.defaults.baseURL = baseUrl;
axios.defaults.withCredentials = false;
axios.defaults.headers.post['Content-Type'] = 'application/json';
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
axios.defaults.headers['authorization'] = (localStorage.getItem('token')) || '';
axios.defaults.timeout = 600000;
axios.defaults.transformRequest = function (data, headers) {
  if (headers['Content-Type'] === 'multipart/form-data') {
    return data;
  } else {
    return JSON.stringify(data);
  }
};

/**
 * 请求拦截器
 * **/
axios.interceptors.request.use(function (config) {
  /* TODO 排除get请求接口 */
  if (config.method != 'get') {
    store.commit('showLoading');
  }
  Vue.prototype.$nextTick(() => {
    Vue.prototype.$common.closeLoadingInit();
  });
  return config;
}, function (error) {
  return Promise.reject(error);
});

/**
 * 响应拦截器
 * **/
axios.interceptors.response.use(function (response) {
  store.commit('hideLoading');
  if (response.data.code === 401) {
    Vue.prototype.$toast('请重新登录');
  }
  if (response.data.code === 500) {
    Vue.prototype.$toast('服务器君离家出走了~');
  }
  return response;
}, function (error) {
  if (error.response.status === 401) {
    Vue.prototype.$toast('请重新登录');
  }
  if (error.response.status === 403) {
    localStorage.removeItem('realName');
  }
  if (error.response.status === 500) {
    Vue.prototype.$toast('服务器出错了');
  }
  return Promise.reject(error);
});
