import 'babel-polyfill';
import Vue from 'vue';
import Es6Promise from 'es6-promise';
import App from './App';
import store from './store/store';
// import axios from 'axios';
import router from './router';
import * as baseUrl from './http/baseUrl';
import '@/http/http.js';
import common from './assets/js/common';
import Vant from 'vant';
import 'vant/lib/index.css';
import md5 from 'js-md5';
import { Toast } from 'vant';

Vue.use(Vant);
Vue.config.productionTip = false;
Vue.prototype.$baseUrl = baseUrl;
Vue.prototype.$common = common;
Vue.prototype.$md5 = md5;

Toast.setDefaultOptions({ duration: 3000 });

Es6Promise.polyfill();
Vue.prototype.setCookie = function (cName, value, expiredays = 100) {
  var exdate = new Date();
  exdate.setDate(exdate.getDate() + expiredays);
  // eslint-disable-next-line
  document.cookie = cName + '=' + escape(value) + ';expires=' + exdate.toGMTString();
};
Vue.prototype.getCookie = function (cName) {
  if (document.cookie.length > 0) {
    var cStart = document.cookie.indexOf(cName + '=');
    // eslint-disable-next-line
    if (cStart != -1) {
      cStart = cStart + cName.length + 1;
      var cEnd = document.cookie.indexOf(';', cStart);
      // eslint-disable-next-line
      if (cEnd == -1) cEnd = document.cookie.length;
      return unescape(document.cookie.substring(cStart, cEnd));
    }
  }
  return '';
};
Vue.prototype.delCookie = function (cName) {
  var exp = new Date();
  exp.setTime(exp.getTime() - 1);
  // 这里需要判断一下cookie是否存在
  var c = this.getCookie(cName);
  // eslint-disable-next-line
  if (c != null){
    document.cookie = cName + '=' + c + ';expires=' + exp.toGMTString();
  }
};

window.getToken = function (str) { // 声明获取token的方法
  localStorage.setItem('token', str);
  store.commit('setToken', str);
};
if (navigator.userAgent.indexOf('ZHANGSHANGKEYUAN') > -1) { // app平台调取原生的token
  if (!localStorage.getItem('token')) {
    // eslint-disable-next-line
    native.getAppUserToken();
  }
}
if (navigator.userAgent.indexOf('ZHANGSHANGKEYUAN') > -1) { // 判断当前平台
  store.commit('setPlatform', 'app');
}

window.getPosition = function (obj) { // 声明获取定位信息的方法
  if (obj.code === 1) {
    store.commit('setPositionInfo', {});
    store.commit('setPositionInfo', obj);
  } else {
    Vue.prototype.$toast(obj.msg);
  }
};
window.getMemberId = function (str) { // 声明memberId的方法
  store.commit('setMemberId', str);
};
let vueApp;
InitApp();

// eslint-disable-next-line
Date.prototype.Format = function (fmt) {
  const o = {
    'M+': this.getMonth() + 1, // 月份
    'd+': this.getDate(), // 日
    'h+': this.getHours(), // 小时
    'm+': this.getMinutes(), // 分
    's+': this.getSeconds(), // 秒
    'q+': Math.floor((this.getMonth() + 3) / 3), // 季度
    'S': this.getMilliseconds() // 毫秒
  };
  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(RegExp.$1, (`${this.getFullYear()}`).substr(4 - RegExp.$1.length));
  }
  for (const k in o) {
    if (new RegExp(`(${k})`).test(fmt)) {
      fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? (o[k]) : ((`00${o[k]}`).substr((`${o[k]}`).length)));
    }
  }
  return fmt;
};

function InitApp () {
  /* eslint-disable no-new */
  vueApp = new Vue({
    el: '#app',
    router,
    store,
    template: '<App/>',
    components: { App }
  });
}

// 设置浏览器标题
Vue.directive('title', {
	inserted: function (el) {
		document.title = el.dataset.title
		}
})
export {vueApp};
