<template>
  <div id="app">
    <loading v-show="loading"></loading>
    <router-view v-if="!isShowPage" />
    <template v-else>
      <!--错误页面-->
      <van-overlay :show="isShowPage" class="flex align-center justify-center">
        <van-loading size="35px" color="#0094ff" type="spinner" text-color="#0094ff" vertical>正在加载页面...</van-loading>
      </van-overlay>
    </template>
    <!-- 拼图验证dome -->
    <van-popup v-model="picturePuzzleShow" round closeable>
      <div class="picture-puzzle-box">
        <div class="image">
          <!-- 拼图大图 -->
          <img class="big-image" :src="`data:image/png;base64,${picturePuzzleMsg.bigImageBase64}`"
               alt="" />
          <img class="small-image" :src="`data:image/png;base64,${picturePuzzleMsg.smallImageBase64}`"
               :style="{top: picturePuzzleMsg.posY + 'px', left: progressNum + '%'}"
               alt="" />
        </div>
        <div class="progress">
          <van-slider v-model="progressNum" active-color="#ebedf0" bar-height="30px" step="1"
                      :disabled="disabled"
                      @change="dragChange"
                      max="87" min="0">
            <template #button>
              <van-button :color="verificationResults == 0 ? '#54c05f' : verificationResults == 1 ? '#da122a' : '#2bb8fe' " style="height: 35px;"
                          :style="{width: picturePuzzleMsg.smallWidth + 4 + 'px'}"
                          >
                <van-icon v-if="verificationResults == 2" class="progress-icon" name="home-o" />
                <van-icon v-if="verificationResults == 0" type="success" name="success" />
                <van-icon v-if="verificationResults == 1" type="warning" name="cross" />
              </van-button>
            </template>
          </van-slider>
        </div>
        <div class="progress-txt" :style="{color: verificationResults == 0 ? '#54c05f' : verificationResults == 1 ? '#da122a' : '' }">
          {{verificationResults == 0 ? '验证成功！' : verificationResults == 1 ? '验证失败！' : '向右滑动滑块完成验证'}}
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import Loading from '@/components/Loading/loading.vue';
import {mapState} from "vuex";
import commonApi from "@/api/common";
export default {
  name: "App",
  components: {Loading},
  data() {
    return {
      isShowPage: false,
      token: null,
      errorTxt: "正在加载页面",
      openId: null, // 微信openId
      unionId: null, // unionId
      source: null,
      // 展示拼图弹窗
      picturePuzzleShow: false,
      // 拼图信息
      picturePuzzleMsg: {},
      // 滑动进度数值
      progressNum: 0,
      // 验证结果
      verificationResults: 2, // 0 成功 1 失败 2 待验证
      // 验证成功之后只读
      disabled: false
    };
  },
  mounted() {
		this.initSaveMsg();
    this.$nextTick(() => {
      this.$common.closeLoadingInit();
    });
  },
  computed: {
    ...mapState(['loading'])
  },
  watch: {
    $route: {
      handler: function () {},
      // 深度观察监听
      deep: true,
    },
  },
  created() {
    // this.getPicturePuzzle()
  },
  methods: {
    /* 获取拼图信息 */
    getPicturePuzzle () {
      let url = `getImageCode`;
      this.axios.get(url)
          .then((res) => {
            if (res.data) {
              this.picturePuzzleMsg = res.data;
            } else {
              this.$toast.fail(res.data.msg);
            }
          });
    },
    /* 滑动结束 */
    dragChange (val) {
      let num = ((this.picturePuzzleMsg.bigWidth - this.picturePuzzleMsg.smallWidth) / 0.87 * val / 100).toFixed(0)
      let url = `verifyImageCode`;
      this.axios.get(url, {
        params: {
          movePosX: num
        }
      })
          .then((res) => {
            this.verificationResults = res.data.errcode
            if (res.data.errcode == 0) {
              this.disabled = true
            } else {
              this.disabled = false
              setTimeout(() => {
                this.progressNum = 0;
                this.verificationResults = 2;
                this.getPicturePuzzle()
              }, 500)
            }
          });
    },
    /* 初始页面获取信息 */
    initSaveMsg() {
      /**
       * 判断是否在微信端：若是1、判断是否有openId 没有通过接口获取 2、没有关注过公众号强制关注公众号
       * **/
      this.openId = this.$common.getQueryString("openId");
      this.unionId = this.$common.getQueryString("unionId");
      this.source = this.$common.getQueryString("source");
      localStorage.setItem("openId", this.openId);
      localStorage.setItem("unionId", this.unionId);

      if (this.source == 2 || this.source == 5 || (this.source == 1 && commonApi.isWxwork())) { // 2 、今日校园  5、I师大  1、红人并且是企业微信
        this.isShowPage = false;
      } else { // 1、红人  3 、微信公众号  4、二维码
        // alert(1);
        this.isGetOpenId()
      }
    },
    /* 是否获取openid 关注微信公众号 */
    isGetOpenId () {
      if (!this.openId) {
        this.isShowPage = true;
        this.getOpenid();
      } else {
        this.isShowPage = false;
        if (this.subscribe === 'false') {
          this.$router.push({name: "officialAccount"});
        }
      }
    },
    /* 微信授权获取openid */
    getOpenid() {
      let returnUrl = encodeURIComponent(window.location.href);
      let baseUrl = window.serverConfig.baseUrl;
      let url = `${baseUrl}wechat/authorize?returnUrl=${returnUrl}&baseUrl=${baseUrl}`;
      window.location.href = url;
    },
  },
};
</script>

<style src="./assets/css/index.scss" lang="scss"></style>
<style lang="scss" scoped>
#app {
  height: 100%;
}
.picture-puzzle-box{
  background: #fff;
  border-radius: 10px;
  height: 350px;
  margin-top:50px;
  .image{
    position: relative;
    text-align: center;
    margin: 20px 10px 10px;
    .big-image{
      border-radius: 5px;
    }
    .small-image{
      position: absolute;
    }
  }
  .progress{
    margin: 10px;
    padding: 0 22px;
    position: relative;
    background: #ebedf0;
    border-radius: 5px;
    i{
      font-size:18px;
    }
    .progress-icon{
      transform: rotate(90deg);
    }
    .progress-txt{
      position: absolute;
      top:0;
      line-height: 30px;
      text-align: center;
      width: calc(100% - 32px);
    }
  }
  .progress-txt{
    text-align: center;
  }

}
</style>
